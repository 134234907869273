<template>
  <div id="lineupGen" class="flex-v">
    <h2>Filters</h2>
    <section id="tabs">
      <input id="lineupTab" name="tabs" type="radio" checked />
      <section>
        <label for="lineupTab">Lineup</label>
        <div>
          <label
            >Minimum Budget Spend<br />
            $<input
              v-model.number="minBudgetSpend"
              type="number"
              name="minBudgetSpend"
              min="0"
              class="m"
            />
          </label>

          <label
            >Minimum Summed Priority Score<br />
            <input
              v-model.number="minPriorityScore"
              type="number"
              name="minPriorityScore"
              min="0"
            />
          </label>
        </div>
      </section>

      <input id="statsTab" name="tabs" type="radio" />
      <section>
        <label for="statsTab">Stat</label>
        <div>
          <label
            >Minimum Captain's Value<br />
            $<input
              v-model.number="minCptValue"
              type="number"
              name="minCptValue"
              min="0"
              class="m"
            />
          </label>
        </div>
      </section>

      <input id="playersTab" name="tabs" type="radio" />
      <section>
        <label for="playersTab">Player</label>
        <div id="narrowList">
          <div v-if="isNarrow">
            <p>Player</p>
            <select @change="playersIndex = $event.target.value">
              <option v-for="(p, i) in players.all" :key="p.id" :value="i">
                {{ p.firstName }} {{ p.lastName }}
              </option>
            </select>

            <p class="valueHeader" @change="toggleValue">
              Value
              <br />
              <input id="regValue" type="radio" name="valueType" checked />
              <label for="regValue">Reg</label>
              <input id="cptValue" type="radio" name="valueType" />
              <label for="cptValue">Cpt</label>
            </p>
            <p>
              ${{
                new Intl.NumberFormat('en-US').format(
                  selectedPlayer.value * vMul
                )
              }}
            </p>

            <p>Exclude From All</p>
            <div>
              <input
                v-model="selectedPlayer.exclude"
                type="checkbox"
                name="exclude"
              /><span></span>
            </div>

            <p>Exclude From Captain</p>
            <div>
              <input
                v-model="selectedPlayer.excludeAsCpt"
                type="checkbox"
                name="excludeAsCpt"
                :disabled="selectedPlayer.exclude"
              /><span></span>
            </div>

            <p>% As Captain <small>(0-10)</small></p>
            <input
              v-model.number="selectedPlayer.targetPerc"
              type="number"
              name="targetPerc"
              min="0"
              max="10"
              :disabled="selectedPlayer.exclude"
            />

            <p>Priority <small>(0-10)</small></p>
            <input
              v-model.number="selectedPlayer.priority"
              type="number"
              name="priority"
              min="0"
              max="10"
              :disabled="selectedPlayer.exclude"
            />

            <p>
              Must Be in Lineup
              <small
                >({{ players.inLineup.length }}/{{
                  config.additionalState.slots
                }})</small
              >
            </p>
            <div @click="playersChange">
              <input
                v-model="selectedPlayer.mustBeInLineup"
                type="checkbox"
                name="mustBeInLineup"
                :disabled="selectedPlayer.exclude"
              /><span></span>
            </div>
          </div>
          <table v-else ref="players" @click="playersChange">
            <thead>
              <tr>
                <th>Player</th>
                <th class="valueHeader" @change="toggleValue">
                  Value
                  <br />
                  <input id="regValue" type="radio" name="valueType" checked />
                  <label for="regValue">Reg</label>
                  <input id="cptValue" type="radio" name="valueType" />
                  <label for="cptValue">Cpt</label>
                </th>
                <th>Exclude From All</th>
                <th>Exclude From Captain</th>
                <th>% As Captain <small>(0-10)</small></th>
                <th>Priority <small>(0-10)</small></th>
                <th>
                  Must Be in Lineup
                  <small
                    >({{ players.inLineup.length }}/{{
                      config.additionalState.slots
                    }})</small
                  >
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="p in players.all"
                :key="p.id"
                :class="{ exclude: p.exclude }"
              >
                <td>{{ p.firstName }} {{ p.lastName }}</td>
                <td>
                  ${{ new Intl.NumberFormat('en-US').format(p.value * vMul) }}
                </td>
                <td>
                  <input
                    v-model="p.exclude"
                    type="checkbox"
                    name="exclude"
                  /><span></span>
                </td>
                <td>
                  <input
                    v-model="p.excludeAsCpt"
                    type="checkbox"
                    name="excludeAsCpt"
                    :disabled="p.exclude"
                  /><span></span>
                </td>
                <td>
                  <input
                    v-model.number="p.targetPerc"
                    type="number"
                    name="targetPerc"
                    min="0"
                    max="10"
                    :disabled="p.exclude"
                  />
                </td>
                <td>
                  <input
                    v-model.number="p.priority"
                    type="number"
                    name="priority"
                    min="0"
                    max="10"
                    :disabled="p.exclude"
                  />
                </td>
                <td>
                  <input
                    v-model="p.mustBeInLineup"
                    type="checkbox"
                    name="mustBeInLineup"
                    :disabled="p.exclude"
                  /><span></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <input id="resultsTab" ref="resultsTab" name="tabs" type="radio" />
      <section>
        <label for="resultsTab">Results</label>
        <div>
          <!-- <h3>Lineups</h3> -->
          <h4>Total Lineups</h4>
          <p>{{ results.totalCount }}</p>

          <h4>After Filters</h4>
          <p>{{ results.combos.length }}</p>

          <h4>Over Max Limit</h4>
          <p>{{ results.truncatedCount }}</p>

          <h4>Lineup # With Specified Players</h4>
          <ul
            v-if="!results.lineupMatches || results.lineupMatches.length"
            class="flex-v"
          >
            <!-- eslint-disable-next-line vue/require-v-for-key -->
            <li v-for="(m, i) in results.lineupMatches">{{ i + 2 }}</li>
          </ul>
          <p v-else>None</p>
          <button
            type="button"
            :disabled="!results.combos.length"
            @click="exportLineups"
          >
            Export
          </button>
          <button
            type="button"
            :disabled="historyLoading"
            @click="$router.push({ name: 'history' })"
          >
            History
          </button>
        </div>
      </section>
    </section>
    <section id="actionBtns">
      <button @click="submit">Generate</button>
      <button disabled>Hand pick</button>
    </section>
  </div>
</template>

<script>
import { some } from '../util/util.js';
import FilterList from '../components/FilterList.vue';
import { LineupGen } from '../components/lineup/gen.js';
import { getConfig, getLimit, setLimit } from '../components/lineup/config.js';
import {
  getDateRange,
  statFiltersToRequestData,
  formatStatFilters,
  applyStatFilters
} from '../components/stat-filter.js';
import api from '../apis/msf-api.js';

export default {
  name: 'LineupGen',
  components: {
    // FilterList
  },
  props: {
    genParams: {
      type: Object,
      default() {
        return {
          config: {
            minBudgetSpend: 49000,
            minCptValue: 4500,
            minPriorityScore: 1
          },
          players: []
        };
      }
    }
  },
  data() {
    return {
      minBudgetSpend: 49000,
      minCptValue: 4500,
      minPriorityScore: 1,
      results: this.resetResults(),
      players: {
        all: [],
        selected: [],
        // a resulting lineup should include all these players
        inLineup: []
      },
      updatePlayers: false,
      statFilters: [],
      loading: false,
      historyLoading: false,
      busy: false,
      // multiplied by player value should give either regular or cpt value
      vMul: 1,
      isNarrow: true,
      playersIndex: 0
    };
  },
  computed: {
    slate() {
      return this.$store.state.selectedSlate;
    },
    contest() {
      return this.slate.contests[0];
    },
    config() {
      return getConfig(this.contest.type, this.players.all);
    },
    limit: {
      get() {
        return getLimit();
      },
      set(n) {
        setLimit(n);
      }
    },
    selectedPlayer() {
      return this.players.all[this.playersIndex] || null;
    }
  },
  async created() {
    if (!this.$store.state.selectedSlate) {
      this.loading = true;
      const index = await this.$store.dispatch(
        `getSlate`,
        +this.$route.params.id
      );

      if (index == -1) {
        this.$router.push({ name: '404' });
      }
      this.loading = false;
    }

    this.minBudgetSpend = this.genParams.config.minBudgetSpend;
    this.minCptValue = this.genParams.config.minCptValue;
    this.minPriorityScore = this.genParams.config.minPriorityScore;

    if (this.genParams.players.length) {
      this.players.all = this.genParams.all;
      this.players.selected = this.genParams.players;
      this.players.inLineup = this.genParams.mustBeInLineup;
    } else {
      const players = this.$store.state.selectedSlate.players;
      this.players.all = [];
      // set expected properties ('position', 'id', 'team', 'name', 'value' come from API)
      for (let i = 0; i < players.length; ++i) {
        this.players.all.push({
          ...players[i],
          mustBeInLineup: false,
          exclude: true,
          excludeAsCpt: true,
          targetPerc: 0,
          priority: 1
        });
      }

      this.players.all.sort((a, b) => b.value - a.value);

      this.updateSelectedPlayers();
    }
  },
  mounted() {
    this.isWindowNarrow();
    window.addEventListener(`resize`, this.isWindowNarrow);
  },
  updated() {
    // * let clicked checkbox update
    // only update when player values were changed
    if (!this.updatePlayers) return;

    this.updateSelectedPlayers();
    this.updatePlayers = false;
  },
  destroyed() {
    window.removeEventListener(`resize`, this.isWindowNarrow);
  },
  methods: {
    resetResults() {
      return { combos: [], totalCount: 0, truncatedCount: 0 };
    },
    async readFilters(e) {
      const { readFile } = await import('../util/file.js');
      const filters = await readFile(e);
      this.statFilters = filters;
    },
    generate(items, filterSet) {
      this.config.additionalState.minBudgetSpend = filterSet.minBudgetSpend;
      this.config.additionalState.minCptValue = filterSet.minCptValue;
      this.config.additionalState.minPriorityScore = filterSet.minPriorityScore;

      // this.config.additionalState.minCptValExclusions = [
      //   14161711,
      //   14161712,
      //   14161713,
      //   14161714,
      //   14161715,
      //   14161716,
      //   14161717,
      //   14161718,
      //   14161719,
      //   14161720,
      //   14161725,
      //   14161726,
      //   14161727,
      //   14161728,
      //   14161729,
      //   14161730,
      //   14161731
      // ];
      return LineupGen.run(items, this.config);
    },
    async submit() {
      const players = this.players.selected;
      const lineupFilters = {
        minBudgetSpend: this.minBudgetSpend,
        minPriorityScore: this.minPriorityScore,
        minCptVal: this.minCptValue
      };

      this.results = this.resetResults();
      this.busy = true;
      this.results = this.generate(
        (await this.filterPlayersByStats(players, this.statFilters)) || players,
        lineupFilters
      );

      // gather filters for included players
      const playerFilters = new Array(players.length);
      for (let i = 0; i < players.length; ++i) {
        const p = players[i];
        playerFilters[i] = {
          excludeAsCpt: p.excludeAsCpt,
          mustInclude: p.mustBeInLineup,
          percAsCpt: p.targetPerc,
          priority: p.priority,
          playerExternId: p.id
        };
      }
      // save lineup gen run
      const saved = this.$store.dispatch(`saveLineupGenFilters`, {
        date: this.slate.forDate,
        ...lineupFilters,
        contest: this.contest.type,
        contestExternId: this.contest.identifier,
        playerFilters
        // statFilters: []
      });

      const lineupMatches = this.findPlayerMatches(
        this.results.combos,
        this.players.inLineup
      );
      this.results.lineupMatches = lineupMatches;
      this.busy = false;

      this.$refs.resultsTab.checked = true;
    },
    async exportLineups(e) {
      e.preventDefault();
      const { writeSheet } = await import('../util/file.js');

      if (!this.results.combos.length) {
        this.results.combos = this.generate(this.players.selected, {
          minBudgetSpend: this.minBudgetSpend,
          minCptValue: this.minCptValue,
          minPriorityScore: this.minPriorityScore
        }).combos;
      }
      this.config.combosToAoA(this.results.combos);
      // download a spreadsheet of resulting combos
      writeSheet(
        `lineups.xlsx`,
        this.config.exportHeaders,
        this.config.combosToAoA(this.results.combos)
      );
    },
    findPlayerMatches(combos, inLineup) {
      let includeLineup = false;
      const lineupMatches = [];
      for (let i = 0; i < combos.length; ++i) {
        const lineup = combos[i];

        for (let j = 0; j < inLineup.length; ++j) {
          if (lineup.players.findIndex(p => p.id === inLineup[j]) == -1) {
            includeLineup = false;
            break;
          }
          includeLineup = true;
        }

        if (includeLineup) {
          lineup.hasSelectedPlayers = true;
          lineupMatches.push({ ...lineup, index: i });
        }
      }

      return lineupMatches;
    },
    getPlayerName(player) {
      return `${player.firstName}${
        player.lastName ? `-${player.lastName}` : ''
      }-${player.id}`;
    },
    async filterPlayersByStats(players, statFilters) {
      const dataToRetrieve = statFiltersToRequestData(players, statFilters);

      const date = this.$store.state.selectedSlate.forDate;
      // build "seasonal player game logs" request string with above data
      const opts = {
        season: `${date.substring(0, 4)}-regular`,
        query: {
          ...(dataToRetrieve.playersToRetrieve.size && {
            player: Array.from(playersToRetrieve.values()).join()
          }),
          ...(dataToRetrieve.statsToRetrieve.size && {
            stats: Array.from(statsToRetrieve.keys()).join()
          }),
          ...(dataToRetrieve.gamesToRetrieve &&
          dataToRetrieve.gamesToRetrieve < 99
            ? // +1 to account for possible "no-play" week
              {
                date: getDateRange(date)
              }
            : false)
        }
      };

      const res = some(opts.query) && (await api.nfl.stats(opts));
      if (!res) {
        // res will be undefined and derail the rest
        return false;
      }
      const formattedData = formatStatFilters(res, statFilters);
      const filteredPlayers = applyStatFilters(
        players,
        formattedData,
        this.statFilters
      );
      // console.log(
      //   `Initial selection:`,
      //   this.players.selected.map(this.getPlayerName),
      //   `Final selection after stat filters:`,
      //   filteredPlayers.map(this.getPlayerName)
      // );
      return filteredPlayers;
    },
    setFilters(filterSet) {
      this.minBudgetSpend = filterSet.minBudgetSpend;
      this.minCptValue = filterSet.minCptVal;
      this.minPriorityScore = filterSet.minPriorityScore;

      const pfs = filterSet.playerFilters;
      const selected = [];
      const mustBeInLineup = [];

      const { all } = this.players;
      for (let i = 0; i < all.length; ++i) {
        all[i].mustBeInLineup = false;
        all[i].exclude = true;
        all[i].excludeAsCpt = true;
        all[i].targetPerc = 0;
        all[i].priority = 1;

        for (let j = 0; j < pfs.length; ++j) {
          if (all[i].id === pfs[j].playerExternId) {
            all[i].exclude = false;
            all[i].excludeAsCpt = pfs[j].excludeAsCpt;
            all[i].mustBeInLineup = pfs[j].mustInclude;
            all[i].targetPerc = pfs[j].percAsCpt;
            all[i].priority = pfs[j].priority;

            pfs[j].mustInclude && mustBeInLineup.push(pfs[j].playerExternId);

            break;
          }
        }
      }

      this.updateSelectedPlayers();
    },
    /********* UI specific *********/
    updateSelectedPlayers() {
      const mustBeInLineup = [];
      const all = this.players.all;
      const selected = [];

      for (let i = 0; i < all.length; ++i) {
        if (!all[i].exclude) {
          selected.push(all[i]);
        }

        all[i].mustBeInLineup && mustBeInLineup.push(all[i].id);
      }

      this.players.selected = selected;
      this.players.inLineup = mustBeInLineup;
    },
    playersChange(evt) {
      if (evt.target.name === 'mustBeInLineup') {
        if (
          evt.target.checked &&
          this.players.inLineup.length + 1 > this.config.additionalState.slots
        ) {
          evt.preventDefault();
          alert(`Maximum reached for the "Must Be in Lineup" column.`);
          return;
        }
      }

      this.updatePlayers = true;
    },
    setToTestDefaults(high = false) {
      this.minBudgetSpend = 0;
      this.minCptValue = 0;

      if (high) {
        for (let i = 0; i < this.players.all.length; ++i) {
          const p = this.players.all[i];

          if (i < this.players.all.length / 3) {
            p.exclude = false;
            p.excludeAsCpt = false;
            p.targetPerc = 10;
          } else {
            p.exclude = true;
          }
        }
      } else {
        const max = this.players.all.length;
        const offset = 16;
        for (let i = 0; i < max; ++i) {
          const p = this.players.all[i];
          if (i < max - offset) {
            p.exclude = true;
          } else if (i < max - offset + 2) {
            p.excludeAsCpt = true;
          }
        }
      }
    },
    toggleValue(e) {
      this.vMul = e.target.id === `regValue` ? 1 : 1.5;
    },
    isWindowNarrow(e) {
      if (
        !!getComputedStyle(
          document.getElementById(`lineupGen`)
        ).getPropertyValue('--narrow') != this.isNarrow
      ) {
        this.isNarrow = !this.isNarrow;
      }
    }
  }
};
</script>

<style>
@media (max-width: 700px) {
  :root {
    --narrow: 1;
  }
}

#lineupGen {
  display: contents;
}

#lineupGen > * + * {
  margin-top: 1rem;
}

#lineupGen h2 {
  text-align: left;
  color: var(--clr-primary);
}

#tabs > section > div > label {
  display: block;
  text-align: left;
}

#tabs > section > div > * + * {
  margin-top: 1.5rem;
}

#tabs > section > div > label > br {
  margin-bottom: 0.5rem;
}

#tabs > section > div > label > input {
  max-width: calc(100% - 2ch);
}

#actionBtns {
  display: flex;
  justify-content: space-around;
}

#actionBtns > button {
  border: none;
  border-radius: 30px;
  padding: 0.75rem 1rem;
  color: var(--clr-primary);
  cursor: pointer;
  font-weight: bold;
}

#actionBtns :first-child {
  background: var(--clr-lt-accent);
}
#actionBtns :last-of-type {
  background: none;
  border: 1px solid var(--clr-lt-accent);
  display: none;
}

/* tab core styles */
#tabs {
  display: grid;
  grid-template-rows: auto 1fr;
  /* following 3 styles are dependent on style preferences */
  grid-template-columns: repeat(3, auto) 1fr;
  gap: 1rem;
  height: 100%;
}

#tabs > input,
#tabs > input:not(:checked) + section > div {
  display: none;
}

#tabs > section {
  display: contents;
}

#tabs > section > label {
  cursor: pointer;
  grid-row: 1;
  position: relative;
  padding: 0.2rem 0;
}

#tabs > section > div {
  grid-row: 2;
  grid-column: 1/-1;
}

/* tab aesthetic styles */
#tabs > section:last-of-type > label {
  margin-left: auto;
}

#tabs > input:checked + section > label {
  color: var(--clr-lt-accent);
}

#tabs > input:checked + section > label::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 80%;
  margin: auto;
  border-bottom: 1px solid var(--clr-lt-accent);
}

#tabs > section > div {
  background: var(--clr-dk-accent);
  border-radius: 30px;
  overflow: auto;
}

#tabs > section:not(:nth-of-type(3)) > div {
  padding: 1rem;
}

#narrowList {
  display: flex;
}
#narrowList > div {
  text-align: left;
  margin: auto;
}
#narrowList p:not(:first-child):not(:nth-of-type(3)) {
  margin-top: 1.5rem;
}
#narrowList p:nth-of-type(3),
#narrowList > div > :not(p) {
  margin-top: 0.5rem;
}

#narrowList > div > .valueHeader {
  display: flex;
  align-items: baseline;
}
#narrowList #regValue + label {
  margin-left: auto;
}

/* players table */
#tabs {
  height: 70%;
}

thead,
tbody,
tr {
  display: contents;
}

#lineupGen table {
  display: grid;
  grid-template-columns: repeat(7, minmax(150px, 1fr));
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 0 0 1rem;
}

#lineupGen table th {
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--clr-dk-accent);
  padding: 1rem;
}

input[type='number'] {
  width: 3.5rem;
  padding: 0.45rem 0.5rem;
  border-radius: 5px;
  border: none;
  margin: 0.125rem;
}
input[type='number'].m {
  width: 5rem;
}
input[type='number']:disabled {
  opacity: 0.5;
}

tr:nth-child(2n-1) td {
  background: #2c2d2c;
}

/* table value header */
#lineupGen .valueHeader [type='radio'] {
  display: none;
}

#lineupGen .valueHeader > label {
  font-size: 0.8rem;
  display: inline;
  padding: 0.3rem;
  cursor: pointer;
}

.valueHeader [type='radio']:checked + label {
  color: var(--clr-primary);
  border-bottom: 1px solid var(--clr-lt-accent);
}

.exclude td {
  text-decoration-line: line-through;
  color: gray;
}
.exclude input,
.exclude input[type='checkbox'] {
  background: var(--clr-dk-accent);
}

.exclude input:not([type='checkbox']) {
  border: 1px solid var(--clr-bg);
}

tr > td:not(:first-of-type) {
  text-align: center;
  position: relative;
}
/* checkbox */
#playersTab + section input[type='checkbox'] {
  appearance: none;
  border: 1px solid var(--clr-secondary);
  border-radius: 4px;
  outline: none;
  transition-duration: 0.2s;
  background-color: var(--clr-dk-accent);
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
}

#playersTab + section input:checked + span {
  position: absolute;
}
#playersTab + section input:checked + span::before {
  content: '\2715';
  display: block;
  text-align: center;
  color: #c14d4d;
  position: absolute;
  right: 0.1rem;
  font-size: 1.3rem;
  pointer-events: none;
}

#playersTab + section input[type='checkbox']:disabled {
  border-color: var(--clr-bg);
  cursor: unset;
}

#playersTab + section input:checked:disabled + span::before {
  color: #613b3b;
}

/* #tabs input[type='radio']:disabled + section > label {
  color: gray;
  cursor: default;
} */
</style>
