<template>
  <div class="autocomplete" @focusout="clickHandler">
    <input
      :value="value"
      v-bind="$attrs"
      type="text"
      autocomplete="off"
      :pattern="options.join('|')"
      title="Must be a valid stat name."
      @focus="focus"
      @input="$emit(`input`, $event.target.value)"
    />
    <div class="optContainer">
      <select v-if="matches.length && show" :size="matches.length">
        <option v-for="(s, i) in matches" :key="i" :value="s">
          {{ s }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Autocomplete',
  inheritAttrs: false,
  props: {
    value: { type: String, default: '' },
    options: { type: Array, required: true }
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    matches() {
      return this.options.filter(o => o.startsWith(this.value));
    }
  },
  methods: {
    clickHandler(e) {
      if (
        e.relatedTarget &&
        e.relatedTarget.closest(`.autocomplete`) &&
        e.relatedTarget.tagName === `SELECT`
      ) {
        this.$emit(`input`, e.relatedTarget.value);
      }

      this.show = false;
    },
    focus() {
      this.show = true;
    }
  }
};
</script>

<style>
.autocomplete {
  position: relative;
}

.autocomplete .optContainer {
  border-radius: 3px;
  color: #000;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.autocomplete select {
  max-height: 100px;
  overflow: auto;
  width: 100%;
  position: relative;
  z-index: 1;
}
</style>
