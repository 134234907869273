function whichGamesIntoNumber(whichGames) {
  switch (whichGames.toLowerCase()) {
    case 'last': {
      return 1;
    }
    case 'last 3': {
      return 3;
    }
    case 'all season': {
      return 99;
    }
    default: {
      // console.log('invalid option for `whichGames`');
    }
  }
}

// ! doesn't account for possibility that player didn't play for > 1 game in a row
/** gets a date range string up to the current game's date from 4 weeks prior (3 + 1 for possible no-game)  */
function getDateRange(gameDate) {
  // end date (current game);
  // go back at least n games before end date
  let start = new Date(gameDate);
  start = new Date(start.setMonth(start.getMonth() - 1)).toISOString();

  const regex = /-|T.+/g;
  return `from-${start.replace(regex, '')}-to-${gameDate.replace(regex, '')}`;
}

function formatStatFilters(playerDatas, filters) {
  // consolidate game logs by player
  const map = {};
  for (let i = 0; i < playerDatas.length; ++i) {
    const p = playerDatas[i];
    if (!p.player) {
      // team DST
      continue;
    }

    const id = p.player.id;
    if (!map[id]) {
      map[`${id}`] = { position: p.player.position, games: [] };
    }

    // stats from MSF are grouped by category, reduce to single object
    let stats = {};
    for (const key in p.stats) {
      stats = { ...stats, ...p.stats[key] };
    }

    map[id].games.push({ id: p.game.id, week: p.game.week, stats });
  }

  return map;
}

function meetsMinStatAvg(min, stat, count, games) {
  const last = games.length - 1;
  let val = 0;
  for (let i = last; i > last - count; --i) {
    val += games[i].stats[stat];
  }
  return val / count >= min;
}

function meetsMinStatEachGame(min, stat, count, games) {
  const last = games.length - 1;
  for (let i = last; i > last - count; --i) {
    if (games[i].stats[stat] < min) {
      return false;
    }
  }
  return true;
}

function applyStatFilters(players, playerDatas, filters) {
  const filteredPlayers = [];
  for (let i = 0; i < players.length; ++i) {
    const p = players[i];
    const pd = playerDatas[p.id];
    if (!pd) {
      // no filter to apply
      filteredPlayers.push(p);
      continue;
    }

    let shouldExclude = false;
    for (const f of filters) {
      if (p.position !== f.position) {
        continue;
      }

      // filter applies to player
      const meetsCriteria =
        f.type === 'Avg' ? meetsMinStatAvg : meetsMinStatEachGame;

      let whichGames = f.whichGames.split(' ')[1];
      whichGames = whichGames ? +whichGames : 1;

      const res = meetsCriteria(f.value, f.stat, whichGames, pd.games);
      if (!res) {
        shouldExclude = true;
        // console.log(
        //   `filtered out ${player.firstName}${
        //     player.lastName ? `-${player.lastName}` : ''
        //   }-${player.id} on filter ${f.position}: ${f.stat} from game(s) ${
        //     f.whichGames
        //   } was < min of ${f.type} of ${f.value}`
        // );
        break;
      }
    }

    if (!shouldExclude) {
      filteredPlayers.push(p);
    }
  }
  return filteredPlayers;
}

function statFiltersToRequestData(players, filters) {
  const playersToRetrieve = new Map();
  const statsToRetrieve = new Map();
  let gamesToRetrieve = 0;
  for (let i = 0; i < filters.length; ++i) {
    const filter = filters[i];

    for (let j = 0; j < players.length; ++j) {
      if (players[j].position === filter.position) {
        // stat filter applies to player
        const p = players[j];
        playersToRetrieve.set(
          p.id,
          `${p.firstName}${p.lastName ? `-${p.lastName}` : ''}`
        );
        statsToRetrieve.set(filter.stat, true);

        // get max number of games to retrieve
        // (from all players' seasons, not just this player)
        const whichGames = whichGamesIntoNumber(filter.whichGames);
        if (whichGames > gamesToRetrieve) {
          gamesToRetrieve = whichGames;
        }
      }
    }
  }

  return { playersToRetrieve, statsToRetrieve, gamesToRetrieve };
}

export {
  statFiltersToRequestData,
  getDateRange,
  formatStatFilters,
  applyStatFilters
};
