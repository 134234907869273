<template>
  <!-- eslint-disable vue/require-v-for-key -->
  <div id="fList">
    <div v-if="!value.length">No stat filters created.</div>
    <ul v-else>
      <li v-for="(f, index) in value">
        <select v-model="f.position" name="pos">
          <option v-for="p in positions" :value="p">{{ p }}</option>
        </select>
        <Autocomplete v-model="f.stat" :options="flatStats" required />
        <select
          :value="f.whichGames.toLowerCase()"
          name="whichGames"
          @change="f.whichGames = $event.target.value"
        >
          <option value="last">Last game</option>
          <option value="last 3">Last 3 games played</option>
          <option value="all season">All season</option>
        </select>
        <select
          :value="f.type.toLowerCase()"
          name="type"
          @change="f.type = $event.target.value"
        >
          <option value="exact">Exact</option>
          <option value="avg">Average</option>
        </select>
        <input v-model="f.value" type="number" name="value" min="0" />
        <button @click="remove(index)">-</button>
      </li>
    </ul>
    <button
      v-if="!showComposer"
      @click="
        showComposer = true;
        selectedIndex = -1;
      "
    >
      +
    </button>
    <form v-else class="statFilter" novalidate @submit.prevent="add">
      <label>
        Position
        <select v-model="tmpFilter.position" name="pos" required>
          <option v-for="p in positions" :value="p">{{ p }}</option>
        </select>
      </label>
      <label>
        Stat
        <Autocomplete v-model="tmpFilter.stat" :options="flatStats" required />
      </label>
      <label>
        Which Games
        <select v-model="tmpFilter.whichGames" name="whichGames">
          <option selected hidden>Select which games</option>
          <option value="last">Last game</option>
          <option value="last 3">Last 3 games played</option>
          <option value="all season">All season</option>
        </select>
      </label>
      <label>
        Type
        <select ref="type" v-model="tmpFilter.type" name="type">
          <option selected hidden>Select type</option>
          <option value="exact">Exact</option>
          <option value="avg">Average</option>
        </select>
      </label>
      <label>
        Value
        <input v-model="tmpFilter.value" type="number" name="value" min="0" />
      </label>

      <div>
        <button type="submit">Add</button>
        <button
          @click="
            showComposer = false;
            resetTmpFilter();
          "
        >
          X
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Autocomplete from './Autocomplete.vue';
export default {
  name: 'FilterList',
  components: { Autocomplete },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      options: [],
      positions: ['DST', 'QB', 'FB', 'RB', 'TE', 'WR'],
      stats: {
        passing: [
          'passAttempts',
          'passCompletions',
          'passPct',
          'passYards',
          'passAvg',
          'passYardsPerAtt',
          'passTD',
          'passTDPct',
          'passInt',
          'passIntPct',
          'passLng',
          'pass20Plus',
          'pass40Plus',
          'passSacks',
          'passSackY',
          'qbRating'
        ],
        rushing: [
          'rushAttempts',
          'rushYards',
          'rushAverage',
          'rushTD',
          'rushLng',
          'rush1stDowns',
          'rush1stDownsPct',
          'rush20Plus',
          'rush40Plus',
          'rushFumbles'
        ],
        receiving: [
          'targets',
          'receptions',
          'recYards',
          'recAverage',
          'recTD',
          'recLng',
          'rec1stDowns',
          'rec20Plus',
          'rec40Plus',
          'recFumbles'
        ],
        tackles: [
          'tackleSolo',
          'tackleTotal',
          'tackleAst',
          'sacks',
          'sackYds',
          'tacklesForLoss'
        ],
        interceptions: [
          'interceptions',
          'intTD',
          'intYds',
          'intAverage',
          'intLng',
          'passesDefended',
          'stuffs',
          'stuffYds',
          'safeties',
          'kB'
        ],
        fumbles: [
          'fumbles',
          'fumLost',
          'fumForced',
          'fumOwnRec',
          'fumOppRec',
          'fumRecYds',
          'fumTotalRec',
          'fumTD'
        ],
        kickoffReturns: [
          'krRet',
          'krYds',
          'krAvg',
          'krLng',
          'krTD',
          'kr20Plus',
          'kr40Plus',
          'krFC',
          'krFum'
        ],
        puntReturns: [
          'prRet',
          'prYds',
          'prAvg',
          'prLng',
          'prTD',
          'pr20Plus',
          'pr40Plus',
          'prFC',
          'prFum'
        ],
        miscellaneous: ['gamesStarted'],
        twoPointAttempts: [
          'twoPtAtt',
          'twoPtMade',
          'twoPtPassAtt',
          'twoPtPassMade',
          'twoPtPassRec',
          'twoPtRushAtt',
          'twoPtRushMade'
        ]
      },
      searchStr: '',
      searching: false,
      showComposer: false,
      tmpFilter: {
        position: '',
        stat: '',
        whichGames: '',
        type: '',
        value: null
      },
      selectedIndex: null
    };
  },
  computed: {
    statMatches() {
      const filteredStats = this.stats;
      return filteredStats;
    },
    flatStats() {
      const stats = [];
      for (const key in this.stats) {
        stats.push(...this.stats[key]);
      }
      return stats;
    }
  },
  methods: {
    add(e) {
      const valid = e.target.checkValidity();

      if (!valid) {
        return;
      }

      this.$emit('input', [...this.value, { ...this.tmpFilter }]);
      this.resetTmpFilter();
    },
    remove(index) {
      const arr = [...this.value];
      arr.splice(index, 1);
      this.$emit('input', arr);
    },
    resetTmpFilter() {
      this.tmpFilter.position = '';
      this.tmpFilter.stat = '';
      this.tmpFilter.whichGames = '';
      this.tmpFilter.type = '';
      this.tmpFilter.value = null;
    }
  }
};
</script>

<style>
#fList {
  text-align: left;
}

#fList > button {
  display: flex;
}

.statFilter {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.statFilter > div {
  flex: 1 0 100%;
  margin-top: 2rem;
  text-align: center;
}

#fList input[type='text'] {
  max-width: 9rem;
}

#fList input[type='number'] {
  max-width: 3rem;
}

#fList li {
  flex-wrap: wrap;
  justify-content: left;
  padding: 0.25rem;
}

#fList label {
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0;
}
</style>
